<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            type="year"
            defaultStart="-1y"
            defaultEnd="0y"
            label="LBLYEAR"
            name="years"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            stepperGrpCd="HEA_INVESTIGATION_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="heaInvestigationStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.heaInvestigationStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      :title="`${heaSurveyCategoryName} 근골격계 기본조사 목록`"
      :columns="gridColumns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" /> 
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'investigationPlan',
  data() {
    return {
      searchParam: {
        plantCd: null,
        heaSurveyCategoryCd: '',
        years: [],
        heaInvestigationStepCd: null,
      },
      grid: {
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    heaSurveyCategoryName() {
      let heaSurveyCategoryName = '';
      switch(this.searchParam.heaSurveyCategoryCd) {
        case 'MSC0000001':
          heaSurveyCategoryName = '정기';
          break;
        case 'MSC0000005':
          heaSurveyCategoryName = '수시';
          break;
        case 'MSC0000010':
          heaSurveyCategoryName = '매년';
          break;
        default:
          heaSurveyCategoryName = '';
          break;
      }
      return heaSurveyCategoryName;
    },
    gridColumns() {
      let cols = [
        {
          name: 'plantName',
          field: 'plantName',
          label: 'LBLPLANT',
          align: 'center',
          sortable: false,
          style: 'width:100px',
        },
        {
          name: 'investigationPlanName',
          field: 'investigationPlanName',
          label: '조사계획명',
          align: 'left',
          sortable: false,
          style: 'width:300px',
          type: 'link'
        },
        {
          name: 'year',
          field: 'year',
          label: 'LBLYEAR',
          align: 'center',
          sortable: false,
          style: 'width:120px',
        },
        {
          name: 'investigationPeriod',
          field: 'investigationPeriod',
          label: '조사기간',
          align: 'center',
          sortable: false,
          style: 'width:180px',
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'LBLMANAGER',
          align: 'center',
          sortable: false,
          style: 'width:120px',
        },
        {
          name: 'heaInvestigationStepName',
          field: 'heaInvestigationStepName',
          label: 'LBLSTEPPROGRESS',
          align: 'center',
          sortable: false,
          style: 'width:100px',
        },
      ];
      switch(this.searchParam.heaSurveyCategoryCd) {
        case 'MSC0000001':
        case 'MSC0000005':
          cols.push({
            name: 'investigationDeptName',
            field: 'investigationDeptName',
            label: '조사부서',
            align: 'center',
            sortable: false,
            style: 'width:250px',
          })
          break;
      }
      return cols;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.muscleSystem.investigationPlan.list.url
      // code setting
      this.$set(this.searchParam, 'heaSurveyCategoryCd', this.$route.meta.params.heaSurveyCategoryCd)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      this.openPop(row)
    },
    openPop(row) {
      if (this.$route.meta.params.heaSurveyCategoryCd !== 'MSC0000010') {
        this.popupOptions.title = `${this.heaSurveyCategoryName} 근골격계 기본조사`;
        this.popupOptions.param = {
          heaInvestigationPlanId: row ? row.heaInvestigationPlanId : '',
          heaSurveyCategoryCd: row ? row.heaSurveyCategoryCd : this.$route.meta.params.heaSurveyCategoryCd,
        };
        this.popupOptions.target = () => import(`${'./investigationPlanDetail.vue'}`);
        this.popupOptions.isFull = true
      } else {
        this.popupOptions.title = `${this.heaSurveyCategoryName} 근골격계 기본조사`;
        this.popupOptions.param = {
          heaInvestigationPlanId: row ? row.heaInvestigationPlanId : '',
          heaSurveyCategoryCd: row ? row.heaSurveyCategoryCd : this.$route.meta.params.heaSurveyCategoryCd,
        };
        this.popupOptions.target = () => import(`${'./investigationPlanEveryYearDetail.vue'}`);
        this.popupOptions.isFull = false
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
